import { useNavigate } from 'react-router-dom';

const useNavigateAndScroll = () => {
  const navigate = useNavigate();

  const navigateAndScroll = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return navigateAndScroll;
};
export default useNavigateAndScroll;