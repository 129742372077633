import React, { useState, useEffect, useRef } from 'react';
import './style.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { CompletedTransactions, PayNow, RequestPaymentDatatable, RequestPayment, AddCustomer, CustomerDatable, GetPaymentPage } from 'fractalpay';


const TransactionsWidget = () => {
    const [scrollTop, setScrollTop] = useState(0);
    const footerRef = useRef(null);

    const scrollToFooter = () => {
        footerRef.current?.scrollIntoView({ behavior: 'smooth' });
    };


    useEffect(() => {
        const handleScroll = () => {
            setScrollTop(window.pageYOffset || document.documentElement.scrollTop);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        const navbar = document.querySelector('.main-nav');

        if (navbar) {
            if (scrollTop > 63) {
                navbar.classList.add('navbar-fixed');
            } else {
                navbar.classList.remove('navbar-fixed');
            }
        }
    }, [scrollTop]);

    const fractalpayClientKey = "2c420c812f87c294d9890b6df134fcd6";
    // const fractalpayClientKey = "7d60d7fa98abbd9d74b27f054f8195cd";



    return (
        <div>
            <Navbar />
            <div className="pay-widget-container">
                <div className="container">
                    <h1>Transactions Widget</h1>
                    <p>Integrating our transactions widget is straightforward, requiring only the addition of a single div class.</p>
                    <div className="pay-widget-content">
                        <div className="row">
                            <section className="txnwidget-section col-md-9">
                                <CompletedTransactions fractalpayClientKey={fractalpayClientKey} />
                            </section>

                            <section className="code-snippet-section col-md-3">
                                <div className="py-8">
                                    <h2>Code Snippet</h2>
                                    <pre>
                                        <code>
{`<table
id="fractal_complete_transactions"
class="table">
</table>`}
                                        </code>
                                    </pre>
                                </div>
                            </section>

                        </div>
                    </div>
                </div>
            </div>
            <Footer ref={footerRef} />
        </div>
    );
};

export default TransactionsWidget;
