import React, { useState } from 'react';
import "./Auth.css";
import logo from "./logo192.png";
import { Modal } from 'react-bootstrap'; // Assuming you're using react-bootstrap for the modal
import axios from 'axios';
import { Puff } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

const apiUrl = 'http://localhost:8081/api/v1';

const Auth1 = () => {
    const [show, setShow] = useState(false); // Toggle modal visibility
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        company_id: 'company_1zxss2',  // Pre-filled value
        business_name: 'Mike LLC',
        dba: 'Mike Ready Mix',
        business_phone: '8599779166',
        business_email: 'mike1@fractalz.com',
        ein: '123456789',
        ownershiptype: 'LLC',
        established_date: '2024-01-01',
        business_address: '333 West Vine Street',
        business_city: 'Lexington',
        business_state: 'KY',
        business_zip: '40517',
        business_country: 'US'
    });
    const [showForm, setShowForm] = useState(false); // Toggle form visibility
    const navigate = useNavigate();

    // Handle form data change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // Function to create a session
    const createSession = async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                `${apiUrl}/auth/create-session`,
                {
                    headers: {
                        'Authorization': 'Basic cGxhdF81YWMxMDljZDc1OjU5NDE1MzJlYTQ5NTQzNjk5NTUyMDgyYWNhYzcyN2NmMDEzMTM1ODFjYWU3YjMzNmUxMjg1NDZmMjM2MDU3MDA='
                    }
                }
            );
            setLoading(false);
            if (response?.data?.result) {
                const sessionToken = response?.data?.data?.sessionToken;
                console.log("Session created, token: ", sessionToken);
                fetchOnboardingData(sessionToken);  // Proceed to onboarding step
            } else {
                console.log("Session creation failed.");
            }
        } catch (error) {
            setLoading(false);
            console.log("Error creating session: ", error);
        }
    };

    // Function to use session token for onboarding
    const fetchOnboardingData = async (session_token) => {
        try {
            setLoading(true);

            // Filter out empty fields from formData
            const filteredData = Object.keys(formData).reduce((acc, key) => {
                if (formData[key]) {
                    acc[key] = formData[key];
                }
                return acc;
            }, {});

            const response = await axios.post(
                `${apiUrl}/auth/onboarding/${session_token}`,
                {
                    company_id: filteredData.company_id,  // Include company_id
                    BusinessInfo: { ...filteredData }
                },
                {
                    headers: {
                        'Authorization': 'Basic cGxhdF81YWMxMDljZDc1OjU5NDE1MzJlYTQ5NTQzNjk5NTUyMDgyYWNhYzcyN2NmMDEzMTM1ODFjYWU3YjMzNmUxMjg1NDZmMjM2MDU3MDA=',
                        'Content-Type': 'application/json'
                    }
                }
            );
            setLoading(false);
            if (response?.data?.result) {
                const onboardingLink = response?.data?.data?.onboarding_link;
                console.log("Onboarding successful: ", response?.data);
                // Redirecting to the onboarding link
                window.location.href = onboardingLink;
            } else {
                console.log("Onboarding failed.");
            }
        } catch (error) {
            setLoading(false);
            console.log("Error fetching onboarding data: ", error);
        }
    };

    // Function to handle the modal confirmation
    const login = () => {
        setShow(false); // Close modal when user confirms
        setShowForm(true); // Show the form after confirmation
    };

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        createSession(); // Proceed with creating the session after form submission
    };

    return (
        <>
            {loading && (
                <>
                    <div className="loader-background"></div> {/* Background blur */}
                    <div className="loader-content">
                        <Puff color="#ffffff" height={100} width={100} />
                    </div>
                </>
            )}

            <div className="card integration">
                <div className='d-flex justify-content-between'>
                    <div className="card-title d-flex">
                        <img src={logo} className="card-icon" alt="Logo" />
                        <div>
                            <strong>Fractal</strong>
                            <div className='not-start'>
                                <svg width="18" height="18" viewBox="0 0 836 836" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="417.948" cy="417.834" r="408.5" transform="rotate(1.32457 417.948 417.834)" fill="#939094" />
                                    <g clipPath="url(#clip0_792_96)">
                                        <path d="M417 662C352.022 662 289.705 636.188 243.759 590.241C197.812 544.295 172 481.978 172 417C172 352.022 197.812 289.705 243.759 243.759C289.705 197.812 352.022 172 417 172C481.978 172 544.295 197.812 590.241 243.759C636.188 289.705 662 352.022 662 417C662 481.978 636.188 544.295 590.241 590.241C544.295 636.188 481.978 662 417 662ZM417 697C491.261 697 562.48 667.5 614.99 614.99C667.5 562.48 697 491.261 697 417C697 342.739 667.5 271.52 614.99 219.01C562.48 166.5 491.261 137 417 137C342.739 137 271.52 166.5 219.01 219.01C166.5 271.52 137 342.739 137 417C137 491.261 166.5 562.48 219.01 614.99C271.52 667.5 342.739 697 417 697Z" fill="white" />
                                        <path d="M422.285 313.925C425.148 312.451 428.361 311.795 431.572 312.03C434.783 312.265 437.867 313.381 440.485 315.255L562.985 402.755C565.254 404.374 567.103 406.511 568.379 408.989C569.655 411.467 570.32 414.213 570.32 417C570.32 419.787 569.655 422.534 568.379 425.011C567.103 427.489 565.254 429.626 562.985 431.245L440.485 518.745C437.868 520.618 434.786 521.733 431.576 521.968C428.367 522.202 425.155 521.547 422.293 520.075C419.432 518.603 417.032 516.37 415.357 513.622C413.682 510.874 412.798 507.718 412.8 504.5V329.5C412.797 326.283 413.68 323.127 415.354 320.379C417.027 317.632 419.425 315.398 422.285 313.925Z" fill="white" />
                                        <rect x="364.5" y="313.4" width="30.1" height="208.6" rx="15.05" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_792_96">
                                            <rect width="560" height="560" fill="white" transform="translate(137 137)" />
                                        </clipPath>
                                    </defs>
                                </svg>

                                <span className='status'>Not Started</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-content">
                    Version 2.4.8
                </div>

                <hr />

                <div className='row d-flex justify-content-between align-items-center'>
                    <div className='start-btn'>
                        <button onClick={() => setShow(true)}>Start</button>
                    </div>
                </div>
            </div>

            {/* Modal */}
            <Modal show={show} onHide={() => setShow(false)} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className='text-center mdl-sec'>
                        <h4>Allow <strong>PLATFORM NAME</strong> to access your Fractal account?</h4>
                        <img src={logo} className="card-icon" alt="Fractal Logo" />
                        <div className='d-flex justify-content-between pt-5'>
                            <button className='start' style={{ marginRight: '10px' }} onClick={() => setShow(false)}>Cancel</button>
                            <button className='start' onClick={login}>Yes, continue to Fractal</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Input form */}
            {showForm && (
                <form onSubmit={handleSubmit}>
                    <h3>Information about the business passed in API Request</h3>
                    <label>
                        Company ID:
                        <input type="text" name="company_id" value={formData.company_id} onChange={handleChange} readOnly />  {/* Read-only input for company_id */}
                    </label>
                    <label>
                        Business Name:
                        <input type="text" name="business_name" value={formData.business_name} onChange={handleChange} />
                    </label>
                    <label>
                        DBA:
                        <input type="text" name="dba" value={formData.dba} onChange={handleChange} />
                    </label>
                    <label>
                        Business Phone:
                        <input type="text" name="business_phone" value={formData.business_phone} onChange={handleChange} />
                    </label>
                    <label>
                        Business Email:
                        <input type="email" name="business_email" value={formData.business_email} onChange={handleChange} />
                    </label>
                    <label>
                        EIN:
                        <input type="text" name="ein" value={formData.ein} onChange={handleChange} />
                    </label>
                    <label>
                        Ownership Type:
                        <input type="text" name="ownershiptype" value={formData.ownershiptype} onChange={handleChange} />
                    </label>
                    <label>
                        Established Date:
                        <input type="date" name="established_date" value={formData.established_date} onChange={handleChange} />
                    </label>
                    <label>
                        Business Address:
                        <input type="text" name="business_address" value={formData.business_address} onChange={handleChange} />
                    </label>
                    <label>
                        Business City:
                        <input type="text" name="business_city" value={formData.business_city} onChange={handleChange} />
                    </label>
                    <label>
                        Business State:
                        <input type="text" name="business_state" value={formData.business_state} onChange={handleChange} />
                    </label>
                    <label>
                        Business Zip:
                        <input type="text" name="business_zip" value={formData.business_zip} onChange={handleChange} />
                    </label>
                    <label>
                        Business Country:
                        <input type="text" name="business_country" value={formData.business_country} onChange={handleChange} />
                    </label>
                    <button type="submit">Submit</button>
                </form>
            )}
        </>
    );
};

export default Auth1;
