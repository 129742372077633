import React, { useState } from 'react';
import './header.css';
import Logo from '../assets/images/fractalfull.svg';

function Header() {

  return (
    <header>
            {/* {showBanner && (
        <div className="coming-soon-banner">
          Coming Soon! Something exciting is on the way.
          <button onClick={closeBanner} className="close-banner">&times;</button>
        </div>
        )} */}
      <nav className="navbar">
        <a href="/" className="logo">
          <img src={Logo} alt="MTrends Logo" width="120" />
        </a>
        
        {/* <ul className="nav-links">
          <li><a href="#">Pricing</a></li>
          <li><a href="#">Payments</a></li>
          <li className="mega-menu-item">
            <a href="#">Solutions</a>
            <div className="mega-menu-content">
              <div className="mega-menu-column">
                <h4>Business Tools</h4>
                <a href="#">Customer Manager</a>
                <a href="#">Inventory Management</a>
                <a href="#">Secure Card Vault</a>
              </div>
              <div className="mega-menu-column">
                <h4>Integrations</h4>
                <a href="#">Third-Party Integrations</a>
              </div>
              <div className="mega-menu-column">
                <h4>Developer Tools</h4>
                <a href="#">APIs</a>
                <a href="#">fractal.js</a>
              </div>
            </div>
          </li>
        </ul> */}
        <div className="nav-actions">
     
          {/* <a href="#" className="btn">Call us</a>
          <a href="#" className="btn">Login</a> */}
          <a href="mailto:reach@fractalpay.com?subject=Interested in Fractal" className="btn btn-primary">Get started</a>
        </div> 
      </nav>
</header>
        
  );
}


export default Header;
