import React from 'react';
import '../style.css';
import '../../../src/App.css';
import Navbar from '../Navbar';
import Footer from '../Footer';

const TermsAndConditions = () => {
    return (
        <div className="App">
        <Navbar />
        <div className="terms-conditions">
            <h1>Fractal Terms and Conditions for Merchant Services</h1>
            <h2>Introduction</h2>
            <p>Welcome to Fractal! We provide electronic payment processing services for merchants through our partnership with Sky Systemz, who acts as our Payment Facilitator. These Terms and Conditions outline your access to and use of our services facilitated by Sky Systemz.</p>
            
            <h2>Definitions</h2>
            <ul>
                <li><strong>Merchant</strong> refers to any business entity that uses our services to process payments.</li>
                <li><strong>Customer</strong> refers to a customer of a Merchant who makes payments using our gateway.</li>
                <li><strong>Services</strong> includes the payment processing functionalities provided through Fractal and managed by Sky Systemz.</li>
                <li><strong>Payment Facilitator (PayFac)</strong> refers to Sky Systemz, who is responsible for managing payment processing and compliance on behalf of Fractal.</li>
                <li><strong>Transaction</strong> means any monetary exchange processed through our services.</li>
            </ul>

            <h2>User Accounts and Registration</h2>
            <p>Upon registering for our Services, you will also be onboarded as a merchant under Sky Systemz, which involves additional verification and agreement to the PayFac's terms and conditions.</p>

            <h2>Services Description</h2>
            <p>We facilitate the processing of credit and debit card transactions, ACH transfers, and digital wallet payments through our PayFac partner.</p>

            <h2>Fees and Payments</h2>
            <p>Fractal charges fees for the use of our services in addition to any fees charged by Sky Systemz. Detailed fee information is available through your account dashboard.</p>

            <h2>User Responsibilities</h2>
            <p>You agree to comply with all laws applicable to your business and transactions, including those specifically related to electronic payments.</p>

            <h2>Prohibited Activities</h2>
            <p>The use of our Services for illegal activities or other prohibited business practices is strictly forbidden and may result in immediate termination of your account.</p>

            <h2>Privacy Policy</h2>
            <p>Our Privacy Policy, available on our website, explains how we handle personal and financial information. It works in conjunction with Sky Systemz's privacy practices.</p>

            <h2>Contact Information</h2>
            <p>For questions or concerns about these Terms or our services, please contact us at support@fractalpay.com.</p>
        </div>
        <Footer />
    </div>
    );
}

export default TermsAndConditions;
