// import logo from './logo.svg';
import React, { useState, useEffect, useRef } from 'react';
import '../../src/App.css'
import './style.css';
import './responsive.css';
import Navbar from './Navbar';
import Footer from './Footer';
<link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet"></link>

const About = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const footerRef = useRef(null);

  const scrollToFooter = () => {
    footerRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.pageYOffset || document.documentElement.scrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const navbar = document.querySelector('.main-nav');

    if (navbar) {
      if (scrollTop > 63) {
        navbar.classList.add('navbar-fixed');
      } else {
        navbar.classList.remove('navbar-fixed');
      }
    }
  }, [scrollTop]);

  return (
    <div className="App">
      <Navbar scrollToFooter={scrollToFooter} />
      <section className='about-banner'>
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-7 about-banner-text">
              <div className='text'>
                <small>Unleashing Payments</small>
                <h1>We’re on<br/> a mission to make<br/> payments simpler</h1>
                <p>It’s almost as if most payment companies want payments to be<br/> complex. But payments don’t have to be difficult. We’re on a<br/> mission to give value back to the companies making a difference in <br/>the world.</p>
                {/* <a href='javascript:void(0)' onClick={() => setShowGetStartedModal(true)}>Get started</a>
      <GetStartedModal showModal={showGetStartedModal} setShowModal={setShowGetStartedModal} /> */}
              <a href='javascript:void(0)' onClick={scrollToFooter}>Get started</a>

              </div>
            </div>
            <div className="col-lg-3 col-md-5 about-banner-img">
              <div className="img">
                <div className="new-pay bounce"><img src="new-payment.png"/></div>
                <img src="about-banner-img.svg"/>
              </div>
            </div>
          </div>
        </div>
      </section>

     

  
      <section className='meet-team'>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
            <div className="heading">
              <h1>Meet the team</h1>
              <p>Help to help you accelerate your payments journey</p>
            </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6">
            <div className="team-box">
              <img src="brian-nichols.jpg"/>
              <h3><a href="https://www.linkedin.com/in/bnichols9883/" target="_blank">Brian Nichols</a></h3>
              <span>Co-founder and CEO</span>
              <p>Founder of several Saas and payment companies including Sky Systemz. Author.</p>
            </div>
            </div>
            <div className="col-md-4 col-sm-6">
            <div className="team-box">
              <img src="james-graves.jpg"/>
              <h3><a href="https://www.linkedin.com/in/iamjamesgreaves/" target="_blank">James Greaves</a></h3>
              <span>Co-founder and President</span>
              <p>Over 20 years in SaaS and payments. Ex-Zelle. Founder of Glyph ID.</p>
            </div>
            </div>
            <div className="col-md-4 col-sm-6">
            <div className="team-box">
              <img src="jason-reed.jpg"/> 
              <h3><a href="https://www.linkedin.com/in/jason-reed-03196852/" target="_blank">Jason Reed</a></h3>
              <span>Sales</span>
              <p>Expert in payments, SaaS and technology sales</p>
            </div>
            </div>
            <div className="col-md-4 col-sm-6">
            <div className="team-box">
              <img src="lvry-semel.jpg"/>
                <h3><a href="https://www.linkedin.com/in/ivrysemel/" target="_blank">Ivry Semel</a></h3>

              <span>Engineering</span>
              <p>Three decades building payment tech, gateways, and services. Columbia MBA.</p>
            </div>
            </div>
            <div className="col-md-4 col-sm-6">
            <div className="team-box">
              <img src="mike-malott.jpg"/>
              <h3><a href="https://www.linkedin.com/in/michael-malott-651992a7/" target="_blank">Mike Malott</a></h3>
              <span>Product</span>
              <p>Seasoned product expert with a dozen complex products built from the ground up.</p>
            </div>
            </div>
            <div className="col-md-4 col-sm-6">
            <div className="team-box">
              <img src="JP.png"/>
              <h3><a href="https://www.linkedin.com/in/jpgound/" target="_blank">JP Gound</a></h3>
              <span>Sales Engineer</span>
              <p>Seasoned sales professional with 7 years of experience</p> 
            </div>
            </div>
            <div className="col-md-4 col-sm-6">
            <div className="team-box">
              <img src="andrew_rice.png"/>
              <h3><a href="https://www.linkedin.com/in/andrewdrice?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAACzA0SwBGc7JMW4qmyv8-p5l3qQxumtk3uE&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_people%3BOO%2FTprP6Q9WXbSh0WJuq8Q%3D%3Dhttps://www.linkedin.com/in/andrewdrice?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAACzA0SwBGc7JMW4qmyv8-p5l3qQxumtk3uE&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_people%3BOO%2FTprP6Q9WXbSh0WJuq8Q%3D%3D" target="_blank">Andrew Rice</a></h3>
              <span>Solutions Architect</span>
              <p>Years of transforming payment landscapes through strategic solutions.</p>
            </div>
            </div>
          </div>
        </div>
      </section>


      <Footer ref={footerRef} />


    </div>
  );
}

export default About;
