import React, { useState, useEffect, useRef } from 'react';
import './style.css';
import InvoiceTable from './InvoiceTable';
import Navbar from './Navbar';
import Footer from './Footer';

const PayWidget = () => {
    const [scrollTop, setScrollTop] = useState(0);
    const footerRef = useRef(null);

    const scrollToFooter = () => {
        footerRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        const handleScroll = () => {
            setScrollTop(window.pageYOffset || document.documentElement.scrollTop);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        const navbar = document.querySelector('.main-nav');

        if (navbar) {
            if (scrollTop > 63) {
                navbar.classList.add('navbar-fixed');
            } else {
                navbar.classList.remove('navbar-fixed');
            }
        }
    }, [scrollTop]);

    return (
        <div>
            <Navbar />
            <div className="pay-widget-container">
            <div className="container">
                <h1>Pay Widget</h1>
                <p>Integrating our pay widget is straightforward, requiring only the addition of a single div class. Your application provides the payment amount directly through the widget.</p>
                <div className="pay-widget-content">
                <div className="row">
                    <section className="invoice-section col-md-8">
                        <InvoiceTable />
                    </section>
                    <section className="code-snippet-section col-md-4">
                        <div className="py-8">
                            <h2>Code Snippet</h2>
                            <pre>
                                <code>
                                    {`<button 
onclick="getPaymentPage(8.00)" 
class="btn-widget" style="display: 
inline-block; color: #000000; cursor: pointer; 
text-decoration: none;">
  Pay
</button>`}
                                </code>
                            </pre>
                        </div>
                    </section>
                </div>
                </div>
            </div>
            </div>
            <Footer ref={footerRef} />
        </div>
    );
};

export default PayWidget;
