import React, { useEffect, useState, useRef } from "react";
import Navbar from "../Navbar";
import "./BlogsPage.css";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";

const cms_server_url = process.env.REACT_APP_CMS_SERVER_URL;

const BlogsPage = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const navigate = useNavigate();
  const footerRef = useRef(null);

  const scrollToFooter = () => {
    footerRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.pageYOffset || document.documentElement.scrollTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const navbar = document.querySelector('.main-nav');

    if (navbar) {
      if (scrollTop > 63) {
        navbar.classList.add('navbar-fixed');
      } else {
        navbar.classList.remove('navbar-fixed');
      }
    }
  }, [scrollTop]);

  const handleNavigate = () => {
    navigate("/blogs-article");
  };

  return (
    <div className="App">
      <Navbar scrollToFooter={scrollToFooter} />

      <section className='banner-blog'>
        <div className="blog-main-part">
          <div className="container">
            <div className="row">
              <div className="col-md-12 blog-banner-text">
                <div className='text'>
                  <h1>Blog</h1>
                </div>
              </div>

              <div className="col-md-6">
                <div className="banner-div">
                  <div className="img">
                    <img src="keystermsinpayments.svg" alt="Recent Post Image" onClick={handleNavigate} />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="blog-banner-text">
                  <div>
                  <small>05.01.24</small>
                    <h2>An Introduction to Payments:<br /> Key Terms and Solutions for SaaS Businesses</h2>
                    <p>An Introduction to Payments:<br />Key Terms and Solutions for SaaS Businesses</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="blog-list">
          <div className="container">
            <div className="row">
              <div className="col-md-12 banner-text">
                <h1>Read more</h1>
              </div>

              <div className="col-md-4">
                <div className="bolg-box">
                  <div className="img">
                    <img className="img-1" src="accelerating_roadmap.svg" alt="Blog Image" onClick={handleNavigate} />
                    <div className="">
                      <small>05.01.24</small>
                      <h5>Accelerating Your Payment Roadmap</h5>
                      <p>Your payment strategy doesn’t need to be stalled on your roadmaps, or overlooked.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="bolg-box">
                  <div className="img">
                    <img className="img-1" src="RegII.svg" alt="Blog Image" onClick={handleNavigate} />
                    <div className="">
                    <small>05.01.24</small>
                      <h5>How Reg II is changing payments</h5>
                      <p>In the dynamic world of finance, regulatory changes can have far-reaching effects on indust...</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="bolg-box">
                  <div className="img">
                    <img className="img-1" src="boost_revenue.svg" alt="Blog Image" onClick={handleNavigate} />
                    <div className="">
                    <small>05.01.24</small>
                      <h5>Boosting Revenue with Embedded Payments: A Strategic Move for SaaS Companies</h5>
                      <p>As layoffs continue in SaaS, what if there was a simple way to instantly add revenue</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <Footer ref={footerRef} />
    </div>
  );
};

export default BlogsPage;
