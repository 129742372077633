import React, { useEffect } from 'react';

const cms_server_url = process.env.REACT_APP_CMS_SERVER_URL;

const DemoTxns = ({ onTxnUrlFetched }) => {
  // Function to fetch transaction details
  const fetchTxnDetails = async () => {
    try {
        const response = await fetch(`${cms_server_url}/api/merchant-txns`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });

      const data = await response.json();
      if (data.result) {
        onTxnUrlFetched(data.paymentUrl); // Notify parent component
      } else {
        alert('Failed to fetch transactions. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  useEffect(() => {
    fetchTxnDetails();
  }, []);

  return null; 
};

export default DemoTxns;
