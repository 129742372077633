// import React, { useState } from 'react';
// import './IFrame.css';

// const cms_server_url = process.env.REACT_APP_CMS_SERVER_URL;

// const Popup = ({ message, onClose }) => (
//   <div className="popup-backdrop">
//     <div className="popup">
//       <p>{message}</p>
//       <button onClick={onClose}>Close</button>
//     </div>
//   </div>
// );

// const DemoRequest = () => {
//   const [amount, setAmount] = useState('');
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [paymentUrl, setPaymentUrl] = useState('about:blank');
//   const [showModal, setShowModal] = useState(false);
//   const [showPopup, setShowPopup] = useState(false);
//   const [popupMessage, setPopupMessage] = useState('');
//   const [isLoading, setIsLoading] = useState(false);

//   const formatCurrency = (value) => {
//     let formattedValue = value.replace(/[^0-9.]/g, '');
//     if (formattedValue.includes('.')) {
//       let parts = formattedValue.split('.');
//       parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//       parts[1] = parts[1].slice(0, 2);
//       formattedValue = parts.join('.');
//     } else {
//       formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//     }
//     return formattedValue;
//   };

//   const handleInputChange = (e) => {
//     const formattedValue = formatCurrency(e.target.value);
//     setAmount(formattedValue);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);

//     const numericAmount = parseFloat(amount.replace(/,/g, '').replace('$', '')).toFixed(2);
//     if (!numericAmount || isNaN(numericAmount)) {
//       setPopupMessage('Please enter a valid amount.');
//       setShowPopup(true);
//       setIsLoading(false);
//       return;
//     }

//     try {
//       const response = await fetch(`${cms_server_url}/api/payment`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ amount: numericAmount, phone_number: phoneNumber })
//       });

//       const data = await response.json();
//       if (data.result && data.data && data.data.payment_link) {
//         setPaymentUrl(data.data.payment_link);
//         setShowModal(true);
//         setPopupMessage('Text request has been sent.');
//         setShowPopup(true);
//         setAmount('');
//         setPhoneNumber('');
//       } else {
//         setPopupMessage(data.message || 'Failed to create the order. Please try again.');
//         setShowPopup(true);
//       }
//     } catch (error) {
//       console.error('Error:', error);
//       setPopupMessage('An error occurred. Please try again.');
//       setShowPopup(true);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="container">
//       <div className="invoice-item">
//         <img src="https://cdn.dribbble.com/users/1158215/screenshots/15718091/media/d2caa924387bd19f3e5f55ee49c71f6c.jpg" alt="Product" />
//         <div className="merchant-name">John Smith</div>
//         <div className="pay-text">Send Request</div>
//         <div className="price">{`$${amount || '0.00'}`}</div>
//       </div>
//       <div className='amount_sale'>
//         <label htmlFor="amountInput" className="form-label">Amount of Sale</label>
//         <input 
//           type="text" 
//           id="amountInput" 
//           className="form-control mb-2" 
//           placeholder="Enter amount" 
//           value={amount} 
//           onChange={handleInputChange} 
//           disabled={isLoading}
//         />
//         <label htmlFor="numberInput" className="form-label">Phone Number</label>
//         <input 
//           type="text" 
//           id="numberInput" 
//           className="form-control mb-2" 
//           placeholder="Enter Phone" 
//           value={phoneNumber}
//           onChange={(e) => setPhoneNumber(e.target.value)}
//           disabled={isLoading}
//         />
//         <button 
//           className="sale-submit-btn" 
//           onClick={handleSubmit} 
//           disabled={isLoading}
//         >
//           {isLoading ? 'Processing...' : 'Send Request'}
//         </button>
//       </div>

//       {showModal && (
//         <div className="modal show">
//           <div className="modal-dialog">
//             <div className="modal-content">
//               <div className="modal-body">
//                 <button type="button" className="close-iframe-btn" onClick={() => setShowModal(false)}>×</button>
//                 <iframe src={paymentUrl} title="Payment" frameBorder="0"></iframe>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
//     </div>
//   );
// };

// export default DemoRequest;


import React, { useState } from 'react';
import './style.css';
import './responsive.css';



// Popup component for displaying messages
const Popup = ({ message, onClose }) => (
  <div className="popup-backdrop">
    <div className="popup">
      <p>{message}</p>
      <button onClick={onClose}>Close</button>
    </div>
  </div>
);

const DemoRequest = () => {
  const [amount, setAmount] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [paymentUrl, setPaymentUrl] = useState('about:blank');
  const [showModal, setShowModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const cms_server_url = process.env.REACT_APP_CMS_SERVER_URL;

  // Handle input change and format the currency
  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '');
    const formattedValue = value.includes('.') ? 
      value.split('.').map((part, idx) => idx === 0 ? part.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : part.slice(0, 2)).join('.') :
      value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setAmount(formattedValue);
  };

  // Form submission handler
  const handleSubmit = async () => {
    setIsLoading(true);
    const numericAmount = parseFloat(amount.replace(/,/g, '').replace('$', '')).toFixed(2);
    if (!numericAmount || isNaN(numericAmount)) {
      setPopupMessage('Please enter a valid amount.');
      setShowPopup(true);
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(`${cms_server_url}/api/payment`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ amount: numericAmount, phone_number: phoneNumber })
      });

      const data = await response.json();
      if (data.result && data.data && data.data.payment_link) {
        setPaymentUrl(data.data.payment_link);
        setShowModal(true);
        setPopupMessage('Text request has been sent.');
        setShowPopup(true);
        setAmount('');
        setPhoneNumber('');
      } else {
        setPopupMessage(data.message || 'Failed to create the order. Please try again.');
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setPopupMessage('An error occurred. Please try again.');
      setShowPopup(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="payment-page">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="white-bx-main">
              <div className="white-bx-top">
                <img src="geometric.svg" alt="Decorative"/>
                <h2>Geometric Brews, inc.</h2>
                <strong><small>Pay geometric brews</small>${amount || '0.00'}</strong>
              </div>
              <div className="black-bx-bottom">
                <div className="pays-row">
                  <label>Amount of Sale</label>
                  <input type="text" placeholder="Enter Amount" value={amount} onChange={handleInputChange} disabled={isLoading} className='white-placeholder' />
                  <label>Phone Number</label>
                  <input type="text" placeholder="Enter Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} disabled={isLoading} className='white-placeholder' />
                </div>
                <div className="pays-row">
                  <button onClick={handleSubmit} disabled={isLoading}>{isLoading ? 'Processing...' : 'Create Sale'}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button type="button" className="close-iframe-btn" onClick={() => setShowModal(false)}>×</button>
            <iframe src={paymentUrl} title="Payment" frameBorder="0" style={{width: '100%', height: '500px'}}></iframe>
          </div>
        </div>
      )}
      {showPopup && <Popup message={popupMessage} onClose={() => setShowPopup(false)} />}
    </section>
  );
};

export default DemoRequest;
