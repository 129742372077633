import React, { useEffect, useState, useRef } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import "../BlogsArticle.css";
import { useNavigate } from "react-router-dom";


const RegII = () => {
  const [content, setContent] = useState("");
  const [headings, setHeadings] = useState([]);
  const footerRef = useRef(null);
  const [scrollTop, setScrollTop] = useState(0);
  const navigate = useNavigate();
  

  const scrollToFooter = () => {
    footerRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.pageYOffset || document.documentElement.scrollTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const navbar = document.querySelector('.main-nav');

    if (navbar) {
      if (scrollTop > 63) {
        navbar.classList.add('navbar-fixed');
      } else {
        navbar.classList.remove('navbar-fixed');
      }
    }
  }, [scrollTop]);

  const initialContent = `
  <h1>How Reg II is Changing Payments</h1>
        <p>In the dynamic world of finance, regulatory changes can have far-reaching effects on industry practices and consumer behavior. One such regulatory update that's making waves is the revision of Regulation II (Reg II), which governs debit card transactions in the United States. Originally established by the Federal Reserve as part of the Dodd-Frank Wall Street Reform and Consumer Protection Act, Reg II is set for significant amendments that are poised to reshape the payments landscape.</p>
        
        <h2>Understanding Regulation II</h2>
        <p>Regulation II, commonly referred to as the "Durbin Amendment," was implemented in 2011 to address issues related to debit card swipe fees charged by banks. These fees, paid by merchants to banks for each debit card transaction, were perceived as excessively high and detrimental to both merchants and consumers. The Durbin Amendment capped these fees for banks with assets over $10 billion, aiming to reduce costs for merchants and, indirectly, for consumers through lower prices.</p>
        <div class="img">
        <img class="img-1" src="RegII.svg" alt="RegII" />
      </div>
        <h2>Key Changes and Their Implications</h2>
        <p>The recent revisions to Reg II focus on enhancing competition and reducing transaction costs further by addressing two main areas:
        Routing Flexibility: The original regulation mandated that merchants must have at least two unaffiliated networks to choose from for processing electronic debit transactions. The amendment goes further by requiring that this choice exists not just for card-present transactions but also for card-not-present transactions, such as online purchases. This change is significant in the context of the growing e-commerce sector, where routing options were previously limited.
        Increased Competition: By expanding routing options for online transactions, the amendments encourage network competition, potentially driving down the transaction fees associated with these networks. This could lead to lower costs for merchants, which might translate into savings for consumers.</p>
        <h2>Industry Response and Challenges</h2>
        <p>The payments industry's response to these changes has been mixed. On one hand, payment networks and large banks are likely to experience increased pressure on their revenue from debit card fees. On the other hand, smaller banks and payment processors see an opportunity to compete more effectively in a market that has been traditionally dominated by a few large players.</p>
        <h2>Consumer Impact</h2>
        <p>For consumers, the potential benefits of the Reg II amendments include lower prices on goods and services, as merchants may pass on savings from lower debit card transaction fees. Additionally, the increased competition among payment networks could lead to better service and innovation in payment technologies.</p>
        <h2>Looking Ahead</h2>
        <p>As the payments industry continues to evolve with technological advancements and changing consumer preferences, regulations like Reg II play a crucial role in shaping the landscape. The amendments to Reg II are expected to enhance competition, reduce costs, and possibly spur innovation in digital payments.</p>
        <h2>Conclusion</h2>
        <p>While the full impact of these changes will unfold over time, the updated Reg II is set to make a significant mark on the payments industry, altering how transactions are processed, how costs are distributed, and ultimately, how consumers and merchants interact in the financial ecosystem.</p>
  `;

  // Function to modify the HTML content and add IDs to headings
  const processContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    const headings = doc.querySelectorAll("h2, h3");
    const headingsInfo = [];

    headings.forEach((heading, index) => {
      const id = `section-${index}-${heading.textContent.toLowerCase().replace(/[^a-z0-9]+/gi, '-')}`;
      heading.id = id;
      headingsInfo.push({ text: heading.textContent, id: id });
    });

    setContent(doc.body.innerHTML);
    setHeadings(headingsInfo);
  };

  useEffect(() => {
    processContent(initialContent);
  }, [initialContent]);

  return (
    <div className="App">
      <Navbar scrollToFooter={scrollToFooter} />

      <div className="breadcrumb-main">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/blogs">Blog</a></li>
                <li className="breadcrumb-item active" aria-current="page">An introduction to payments</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <section className='blog-details'>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <ul className="blog-links">
                {headings.map(heading => (
                  <li key={heading.id}><a href={`#${heading.id}`}>{heading.text}</a></li>
                ))}
              </ul>
            </div>
            <div className="col-md-8" dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      </section>

      <Footer ref={footerRef} />
    </div>
  );
};

export default RegII;
