// import logo from './logo.svg';
import React, { useState, useEffect } from "react";
import "../../src/App.css";
import "./style.css";
import "./responsive.css";
import "./quick-pay.css";

<link
  href="https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
  rel="stylesheet"
></link>;

const CreatePayment = () => {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.pageYOffset || document.documentElement.scrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); 

  useEffect(() => {
    const navbar = document.querySelector(".main-nav");

    if (navbar) {
      if (scrollTop > 63) {
        navbar.classList.add("navbar-fixed");
      } else {
        navbar.classList.remove("navbar-fixed");
      }
    }
  }, [scrollTop]);

  const [isVisible, setIsVisible] = useState(false);

  const toggleData = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="row bg-light no-gutters">
      <div className="col-sm-12">
        <div className="bd-example bd-example-tabs right-tabs mttabs m-3 ">
          <div className="payment-popup">
            <button className="close-pop">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="37"
                height="37"
                viewBox="0 0 21 21"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  // stroke-linecap="round"
                  // stroke-linejoin="round"
                  d="m7.5 7.5l6 6m0-6l-6 6"
                />
              </svg>
            </button>
            <div className="row">
              <div className="comp-name">
                <img src="comp-logo.png" alt="" className="client-logo" />
              </div>
              <div className="container">
                <div id="Checkout" className="inline">
                  <div className="header">
                    <h1>Pay</h1>
                    <button onClick={toggleData} >                   
                     <i
                      className="fa fa-qrcode"
                      id="qrIcon"
                      title="Show QR Code"
                    ></i>
                    </button>

                  </div>
                  <div id="target" className="data" style={{ display: isVisible ? 'block' : 'none' }}>
                    <img src="qrcode.png" />
                  </div>

                  <form
                    method="post"
                    id="PaymentForm"
                    className="payment-form"
                    action=""
                    accept-charset="UTF-8"
                    enctype="application/x-www-form-urlencoded"
                  >
                    <input
                      type="hidden"
                      id="dctoken"
                      value="911d77ff0de047818c71e02f13af7ea8"
                      name="dctoken"
                    />

                    <div className="form-group top-amnt">
                      <div className="amtleft">
                        <label for="Amount">Payment amount</label>
                        <div className="amount-placeholder">
                          <span>$</span>
                          <span> 26 </span>
                        </div>
                      </div>
                      <div className="card-row">
                        <span className="visa"></span>
                        <span className="mastercard"></span>
                        <span className="amex"></span>
                        <span className="discover"></span>
                      </div>
                    </div>

                    <div className="form-group">
                      <label for="NameOnCard">Name on card</label>
                      <input
                        id="NameOnCard"
                        className="form-control required"
                        type="text"
                        // value=""
                        maxlength="100"
                        placeholder="John Doe"
                      />
                      <span
                        id="NameOnCard-error"
                        style={{ color: "red", display: "none" }}
                      ></span>
                    </div>

                    <div className="form-group">
                      <label for="CardDetails">Card DETAILS</label>
                      <input
                        data-token="card_number"
                        className="null card-image form-control required"
                        type="text"
                        maxlength="19"
                        // value=""
                        placeholder="0000 0000 0000 0000"
                      />
                      <span
                        id="card_number-error"
                        style={{ color: "red", display: "none" }}
                      ></span>
                    </div>

                    <div className="expiry-date-group form-group">
                      <input
                        data-token="exp_month"
                        // value=""
                        className="form-control required"
                        type="text"
                        placeholder="MM"
                        maxlength="2"
                      />
                      <span
                        id="exp_month-error"
                        style={{ color: "red", display: "none" }}
                      ></span>
                    </div>

                    <div className="expiry-date-group form-group">
                      <input
                        data-token="exp_year"
                        // value=""
                        className="form-control yer required"
                        type="text"
                        placeholder="YYYY"
                        maxlength="4"
                      />
                      <span
                        id="exp_year-error"
                        style={{ color: "red", display: "none" }}
                      ></span>
                    </div>

                    <div className="security-code-group form-group">
                      <div className="input-container">
                        <input
                          data-token="cvv"
                          // value=""
                          className="form-control required"
                          type="text"
                          maxlength="4"
                          placeholder="CVC"
                        />
                        <i id="cvc" className="fa fa-question-circle"><div className="cvc-preview-container two-card hide">
                          <div className="amex-cvc-preview"></div>
                          <div className="visa-mc-dis-cvc-preview"></div>
                        </div></i>
                        <span
                          id="cvv-error"
                          style={{ color: "red", display: "none" }}
                        ></span>
                      </div>

                    </div>

                    <div className="zip-code-group form-group">
                      <label for="ZIPCode">Postal code</label>
                      <div className="input-container">
                        <input
                          id="ZIPCode"
                          className="form-control required"
                          type="text"
                          maxlength="7"
                          placeholder="000000"
                        />

                        <div id="zipcode">
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20">
                            <path fill="#BEB8C7" d="M10 20a10 10 0 1 1 0-20a10 10 0 0 1 0 20zm2-13c0 .28-.21.8-.42 1L10 9.58c-.57.58-1 1.6-1 2.42v1h2v-1c0-.29.21-.8.42-1L13 9.42c.57-.58 1-1.6 1-2.42a4 4 0 1 0-8 0h2a2 2 0 1 1 4 0zm-3 8v2h2v-2H9z" />
                          </svg>
                          <div className="zip-tip">Enter the ZIP/Postal code for your credit card billing address.</div>
                        </div>

                        <span
                          id="ZIPCode-error"
                          style={{ color: "red", display: "none" }}
                        >
                          This field is required
                        </span>
                      </div>
                    </div>
                    <button
                      id="PayButton"
                      className="btn btn-block btn-success submit-button"
                      type="submit"
                    >
                      <span className="submit-button-lock"></span>
                      <span className="align-middle">Pay $25.99</span>
                    </button>
                  </form>
                  <div className="powerd-by-part">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M6.615 21q-.666 0-1.14-.475Q5 20.051 5 19.385v-8.77q0-.666.475-1.14Q5.949 9 6.615 9H8V7q0-1.671 1.164-2.836T12 3q1.671 0 2.836 1.164T16 7v2h1.385q.666 0 1.14.475q.475.474.475 1.14v8.77q0 .666-.475 1.14q-.474.475-1.14.475H6.615ZM12 16.5q.633 0 1.066-.434q.434-.433.434-1.066t-.434-1.066Q12.633 13.5 12 13.5t-1.066.434Q10.5 14.367 10.5 15t.434 1.066q.433.434 1.066.434ZM9 9h6V7q0-1.25-.875-2.125T12 4q-1.25 0-2.125.875T9 7v2Z" />
                    </svg>
                    Secure payments powered by Fractal
                    <img
                      src="favicon.ico"
                      alt="Fractal logo"
                      className="powered-logo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePayment;
