import React, { useState } from 'react';
import Header from './header';
import DemoRequest from './DemoRequest';
import Newiframe from './NewIframe';
import DemoTxns from './DemoTxns';
import '../App.css';
import './PayFrameDemo.css';

const PayFrameDemo = () => {
  const [currentTab, setCurrentTab] = useState('payFrame');
  const [iframeUrl, setIframeUrl] = useState('');

  // Function to handle URL updates from child components
  const handleIframeUrlUpdate = (url) => {
    setIframeUrl(url);
  };

  const renderContent = () => {
    switch (currentTab) {
      case 'payFrame':
        // If Demoiframe doesn't inherently use an iframe, consider loading its content directly here
        // Otherwise, ensure it can display or trigger an iframe URL within `fake-browser-content`
        return <Newiframe onIframeUrlUpdate={handleIframeUrlUpdate} />;
      case 'textRequest':
        return <DemoRequest />;
      case 'hostedOnboarding':
        return (
          <iframe
            src="https://staging-hosted.fractalpay.com/merchant-onboarding/0a536b8f8cdba20c6bef714cc7389ff8"
            style={{ width: '100%', height: '100%', border: 'none' }}
            title="Hosted Onboarding Content"
          />
        );
      case 'hostedTransactions':
        return <DemoTxns onTxnUrlFetched={handleIframeUrlUpdate} />;
        default:
        return null;
    }
  };

  return (
    <>
      <Header />
      {/* <div className="header-container">
        <h2>Explore the Fractal Hosted Solution</h2>
      </div> */}

      {/* <div className="grad-body"> */}

      <div className="hosted-tabs">
        <button className={currentTab === 'payFrame' ? 'hosted-tab active' : 'hosted-tab'} onClick={() => setCurrentTab('payFrame')}>PayFrame</button>
        <button className={currentTab === 'textRequest' ? 'hosted-tab active' : 'hosted-tab'} onClick={() => setCurrentTab('textRequest')}>Text Request</button>
        <button className={currentTab === 'hostedOnboarding' ? 'hosted-tab active' : 'hosted-tab'} onClick={() => setCurrentTab('hostedOnboarding')}>Hosted Onboarding</button>
        <button className={currentTab === 'hostedTransactions' ? 'hosted-tab active' : 'hosted-tab'} onClick={() => setCurrentTab('hostedTransactions')}>Hosted Transactions</button>
      </div>

      <div className="fake-browser">
        <div className="fake-browser-header">
          <div className="fake-control-buttons">
            <div className="fake-control-button close"></div>
            <div className="fake-control-button minimize"></div>
            <div className="fake-control-button maximize"></div>
          </div>
          <span className="fake-url-bar">https://www.yoursite.com</span>
        </div>
        <div className="fake-browser-content">
          {renderContent()}
          {(currentTab === 'hostedOnboarding' || currentTab === 'hostedTransactions') && iframeUrl && (
            <iframe src={iframeUrl} style={{ width: '100%', height: '100%', border: 'none' }} title="Embedded Content"></iframe>
          )}
        </div>
      </div>
    {/* </div> */}
    </>
  );
};

export default PayFrameDemo;
