import React, { useState, useEffect } from 'react';
import './style.css';
import './responsive.css'; 



const Newiframe = () => {
  const [amount, setAmount] = useState('');
  const [paymentUrl, setPaymentUrl] = useState('about:blank');
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleInputChange = (e) => {
    // Formatting currency value
    const value = e.target.value.replace(/[^0-9.]/g, '');
    const formattedValue = value.includes('.') ? 
      value.split('.').map((part, idx) => idx === 0 ? part.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : part.slice(0, 2)).join('.') :
      value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setAmount(formattedValue);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const numericAmount = parseFloat(amount.replace(/,/g, '').replace('$', '')).toFixed(2);
    if (!numericAmount || isNaN(numericAmount)) {
      alert('Please enter a valid amount.');
      setIsLoading(false);
      return;
    }

    const cms_server_url = process.env.REACT_APP_CMS_SERVER_URL;
    try {
      const response = await fetch(`${cms_server_url}/api/getPaymentUrl`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ amount: numericAmount })
      });

      if (response.ok) {
        const data = await response.json();
        setPaymentUrl(data.paymentUrl);
        setShowModal(true);
      } else {
        alert('Failed to create the order. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
<section className="payment-page">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="white-bx-main">
            <div className="white-bx-top">
              <img src="geometric.svg" alt="Decorative"/>
              <h2>Geometric Brews, inc.</h2>
              <strong><small>Pay geometric brews</small>${amount || '0.00'}</strong>
            </div>
            <div className="black-bx-bottom">
              <div className="pays-row">
                <label>Amount of Sale</label>
                <input type="text" placeholder="Enter Amount" value={amount} onChange={handleInputChange} disabled={isLoading} className='white-placeholder' />
              </div>
              <div className="pays-row">
                <button onClick={handleSubmit} disabled={isLoading}>{isLoading ? 'Processing...' : 'Create Sale'}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button type="button" className="close-iframe-btn" onClick={() => setShowModal(false)}>×</button>
            <iframe src={paymentUrl} title="Payment" frameBorder="0" style={{width: '100%', }}></iframe>
          </div>
        </div>
      )}
    </div>
    </section>
  );
};

export default Newiframe;
