import React, { useState, forwardRef } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../components/responsive.css";


const cms_server_url = process.env.REACT_APP_CMS_SERVER_URL;


const Footer = forwardRef(({ includeForm = true }, ref) => {
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        subject:"Get in touch"
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            toast.error('Please enter a valid email address!', { className: 'toast-error' });
            return;
        }

        if (formData.name === '' || formData.company === '' || formData.subject === '') {
            toast.error('Please fill in all fields!', { className: 'toast-error' });
            return;
        }

        const formDataEncoded = new URLSearchParams();
        formDataEncoded.append('name', formData.name);
        formDataEncoded.append('company', formData.company);
        formDataEncoded.append('email', formData.email);
        formDataEncoded.append('subject', formData.subject);

        fetch(`${cms_server_url}/sendContactForm`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formDataEncoded.toString(),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            toast.success('Email sent successfully!');
            setFormData({
                name: '',
                company: '',
                email: '',
            });
        })
        .catch(error => {
            toast.error('An error occurred while sending the form!');
            console.error('Error:', error);
        });
    };

    return (
        <>
            {/* <ToastContainer /> */}
            <footer ref={ref}>
                <div className='footer1'>
                    {includeForm && (
                        <div className="get-in-touch">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="get-touch">
                                            <small>Contact</small>
                                            <h2>Get in touch</h2>
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <label>Name</label>
                                                        <input
                                                            required
                                                            type="text"
                                                            placeholder="Enter your name"
                                                            name="name"
                                                            value={formData.name}
                                                            onChange={handleChange}
                                                            className="white-placeholder"
                                                        />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label>Company</label>
                                                        <input
                                                            required
                                                            type="text"
                                                            placeholder="Enter your company"
                                                            name="company"
                                                            value={formData.company}
                                                            onChange={handleChange}
                                                            className="white-placeholder"
                                                        />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label>Email</label>
                                                        <input
                                                            required
                                                            type="email"
                                                            placeholder="Enter your email"
                                                            name="email"
                                                            value={formData.email}
                                                            onChange={handleChange}
                                                            className="white-placeholder"
                                                        />
                                                    </div>
                                                  
                                                    <div className="col-md-12">
                                                        <button type="submit">Send</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className='footer2'>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <a href='javascript:void(0)' className='footer-logo'><img src='Logo_Fractal_White_Icon.svg' /></a>
                                <ul className='footer-social'>
                                    {/* <li><a href='javascript:void(0)'><svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M17.751 3h3.067l-6.7 7.625L22 21h-6.172l-4.833-6.293L5.464 21h-3.07l7.167-8.155L2 3h6.328l4.37 5.752zm-1.076 16.172h1.7L7.404 4.732H5.58z" />
                                    </svg></a></li> */}
                                    <li><a href='https://www.linkedin.com/company/fractalpayments'><svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 24 24">
                                        <g fill="currentColor">
                                            <path fill-rule="evenodd" d="M12.5 8.8v1.7a3.7 3.7 0 0 1 3.3-1.7c3.5 0 4.2 2.2 4.2 5v5.7h-3.2v-5c0-1.3-.2-2.8-2.1-2.8c-1.9 0-2.2 1.3-2.2 2.6v5.2H9.3V8.8zM7.2 6.1a1.6 1.6 0 0 1-2 1.6a1.6 1.6 0 0 1-1-2.2A1.6 1.6 0 0 1 6.6 5c.3.3.5.7.5 1.1Z" clip-rule="evenodd" />
                                            <path d="M7.2 8.8H4v10.7h3.2z" />
                                        </g>
                                    </svg></a></li>
                                    {/* <li><a href='javascript:void(0)'><svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 24 24">
                                        <g fill="none" fill-rule="evenodd">
                                            <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                                            <path fill="currentColor" d="M19.777 4.43a1.5 1.5 0 0 1 2.062 1.626l-2.268 13.757c-.22 1.327-1.676 2.088-2.893 1.427c-1.018-.553-2.53-1.405-3.89-2.294c-.68-.445-2.763-1.87-2.507-2.884c.22-.867 3.72-4.125 5.72-6.062c.785-.761.427-1.2-.5-.5c-2.303 1.738-5.998 4.381-7.22 5.125c-1.078.656-1.64.768-2.312.656c-1.226-.204-2.363-.52-3.291-.905c-1.254-.52-1.193-2.244-.001-2.746l17.1-7.2Z" />
                                        </g>
                                    </svg></a></li> */}
                                </ul>
                            </div>
                            <div className="col-md-8">
                                <div className='footer-menu'>

                                    {/* <ul>
                                        <small>Platform</small>
                                        <li><a href='javascript:void(0)'>Products</a></li>
                                        <li><a href='javascript:void(0)'>Pricing</a></li>
                                        <li><a href='javascript:void(0)'>Blog</a></li>
                                    </ul> */}

                                   <ul>
                                        <small>Company</small>
                                        <li><a href='/About'>About</a></li>
                                        <li><a href="#" class="termly-display-preferences">Consent Preferences</a></li>
                                        {/* <li><a href='javascript:void(0)'>Press</a></li>
                                        <li><a href='javascript:void(0)'>Careers</a></li> */}
                                    </ul>

                                    <ul>
                                        <small>Developers</small>
                                    <li><a href='https://docs.fractalpay.com/' target='_blank'>Documentation</a></li>
                                    <li><a href='https://docs.fractalpay.com/api-reference/introduction' target='_blank'>API Reference</a></li>
                                    {/* <li><a href='javascript:void(0)'>Changelog</a></li>
                                    <li><a href='javascript:void(0)'>Status</a></li> */}
                                </ul>

                                <ul>
                                        <small>Policies</small>
                                        <li><a href='https://app.termly.io/policy-viewer/policy.html?policyUUID=1fd132e7-636d-422d-a1d2-b2a4006f8688'>Terms of Use</a></li>
                                        <li><a href='https://app.termly.io/policy-viewer/policy.html?policyUUID=d91ff3f3-d298-42ee-9a22-ffc7b56b8ff5'>Privacy Policy</a></li>
                                        <li><a href='https://app.termly.io/policy-viewer/policy.html?policyUUID=72a4ba4f-6588-4e77-8527-c6c2c6ae4532'>Cookie Policy</a></li>
                                        <li><a href='https://app.termly.io/policy-viewer/policy.html?policyUUID=25953095-c3f4-4a04-bf4c-73305a1cf048'>Disclaimer</a></li>
                                        <li><a href='https://app.termly.io/notify/d91ff3f3-d298-42ee-9a22-ffc7b56b8ff5'>DSAR Form</a></li>
                                       
                                        {/* <li><a href='javascript:void(0)'>Press</a></li>
                                        <li><a href='javascript:void(0)'>Careers</a></li> */}
                                    </ul>
                                
                                <ul className="f-contact">
                                    <small>Connect</small>
                                    <li>reach@fractalpay.com</li>
                                    <li>+1 (513) 201-7420</li>
                                    <li>35E 7th St, Suite 715. <br/>Cincinnati, OH 45202</li>
                                    <small>Corporate Address</small>
                                    <li>1420 Celebration Blvd STE 200. <br/> Celebration, FL 34747</li>
                                </ul>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='copy-right-main'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="copy-right">
                                © 2024 Fractal
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer >
        </>
    );
});

export default Footer;
