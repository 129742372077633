import React, { useState } from "react";
import { Modal } from 'react-bootstrap';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const cms_server_url = process.env.REACT_APP_CMS_SERVER_URL;


const ACHQuote = ({ showModals, setShowModals }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject:"Get a Quote"
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.email === "" || formData.name === "") {
      toast.error("Please fill in all fields!", { className: "toast-error" });
      return;
    }

    try {
      const response = await fetch(`${cms_server_url}/sendContactForm`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setShowModals(false);
      toast.success('Quote submitted successfully!');
    } catch (error) {
      toast.error('An error occurred while submitting the quote!');
    } finally {
      setFormData({
        name: "",
        email: "",
      });
    }
  };

  return (
    <>
      <Modal show={showModals} onHide={() => setShowModals(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Get a Quote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form style={{ display: 'flex', flexDirection: 'column' }} onSubmit={handleSubmit}>
            <label>Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <button type="submit" style={{
              backgroundColor: 'black',
              color: 'white',
              display: 'block',
              margin: '10px auto',
              fontSize: '14px',
              padding: '5px 10px',
              borderRadius: '4px',
            }}>Submit</button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default ACHQuote;