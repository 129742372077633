// import * as React from "react";
import React, { useState, useEffect } from 'react';
import InvoiceTable from './InvoiceTable';

import {
  CompletedTransactions,
  PayNow,
  RequestPaymentDatatable,
  RequestPayment,
  AddCustomer,
  CustomerDatable,
  RequestPaymentonClick,
  GetPaymentPage
} from 'fractalpay';


function DashboardTest() {
  const paymentData = [
    {
      title: "Payments",
    },
  ];
  const invoicesData = [
    {
      title: "Invoices",
    },
  ];
  const customersData = [
    {
      title: "Customers",
    },
  ];
  const fractalpayClientKey = "4ce6bd7335f19c8c8ae164a0c3e9d6f8"
  const amount= "8"
  const phone_number = "9695969889"
  return (
    <>
      <style jsx>{`
        .dashboard {
          background-color: #eceff4;
          display: flex;
          padding-bottom: 80px;
          flex-direction: column;
        }
        .header {
          backdrop-filter: blur(6px);
          background-color: rgba(255, 255, 255, 0.4);
          display: flex;
          width: 100%;
          flex-direction: column;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.28px;
          line-height: 150%;
          justify-content: center;
          padding: 14px 20px;
        }
        @media (max-width: 991px) {
          .header {
            max-width: 100%;
          }
        }
        .header-content {
          align-items: center;
          display: flex;
          width: 100%;
          gap: 20px;
          justify-content: space-between;
        }
        @media (max-width: 991px) {
          .header-content {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .logo {
          aspect-ratio: 6.25;
          object-fit: auto;
          object-position: center;
          width: 152px;
          align-self: stretch;
          max-width: 100%;
          margin: auto 0;
        }
        .nav-menu {
          align-items: center;
          align-self: stretch;
          display: flex;
          gap: 10px;
          color: var(--Black, #161616);
          white-space: nowrap;
          justify-content: space-between;
        }
        @media (max-width: 991px) {
          .nav-menu {
            flex-wrap: wrap;
            white-space: initial;
          }
        }
        .nav-item-active {
          font-family: Inter, sans-serif;
          justify-content: center;
          border-radius: 1000px;
          background-color: var(--Black, #161616);
          align-self: stretch;
          color: #fff;
          padding: 6px 14px;
        }
        @media (max-width: 991px) {
          .nav-item-active {
            white-space: initial;
          }
        }
        .nav-item {
          font-family: Inter, sans-serif;
          align-self: stretch;
          margin: auto 0;
        }
        .user-info {
          align-self: stretch;
          display: flex;
          gap: 7px;
          color: #35254d;
          text-align: right;
          margin: auto 0;
        }
        .user-name {
          font-family: Inter, sans-serif;
          margin: auto 0;
        }
        .user-avatar {
          background-color: linear-gradient(
            146deg,
            #b8a6d1 20.09%,
            #f1edf7 93.87%
          );
          border-radius: 50%;
          width: 30px;
          height: 30px;
        }
        .page-header {
          justify-content: space-between;
          align-self: center;
          display: flex;
          margin-top: 40px;
          gap: 0px;
          font-weight: 500;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .page-header {
            flex-wrap: wrap;
          }
        }
        .page-title {
          color: var(--Black, #161616);
          letter-spacing: -0.48px;
          margin: auto 0;
          font: 24px/133% Inter, sans-serif;
        }
        .page-actions {
          justify-content: end;
          display: flex;
          padding-left: 80px;
          gap: 6px;
          font-size: 14px;
          white-space: nowrap;
          letter-spacing: -0.28px;
          line-height: 24px;
          flex: 1;
        }
        @media (max-width: 991px) {
          .page-actions {
            flex-wrap: wrap;
            white-space: initial;
          }
        }
        .add-button {
          font-family: Inter, sans-serif;
          justify-content: center;
          border-radius: 12px;
          border-color: rgba(228, 220, 235, 1);
          border-style: solid;
          border-width: 1px;
          background-color: #fff;
          color: var(--Purple, #35254d);
          padding: 6px 12px;
        }
        @media (max-width: 991px) {
          .add-button {
            white-space: initial;
          }
        }
        .done-button {
          font-family: Inter, sans-serif;
          justify-content: center;
          border-radius: 12px;
          background-color: var(--Black, #161616);
          color: #fff;
          padding: 6px 12px;
        }
        @media (max-width: 991px) {
          .done-button {
            white-space: initial;
          }
        }
        .date-range {
          align-self: start;
          display: flex;
          gap: -1px;
          font-size: 14px;
          color: var(--Black, #161616);
          font-weight: 500;
          letter-spacing: -0.28px;
          line-height: 24px;
          justify-content: space-between;
          margin: 26px 0 0 20px;
        }
        @media (max-width: 991px) {
          .date-range {
            margin-left: 10px;
          }
        }
        .date-range-label {
          justify-content: center;
          border-radius: 12px 0px 0px 12px;
          border-color: rgba(210, 219, 230, 1);
          border-style: solid;
          border-width: 1px;
          background-color: #fff;
          display: flex;
          gap: 10px;
          padding: 6px 12px;
        }
        .date-range-text {
          font-family: Inter, sans-serif;
        }
        .date-range-icon {
          aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
          width: 12px;
          margin: auto 0;
        }
        .date-range-value {
          font-family: Inter, sans-serif;
          justify-content: center;
          border-radius: 0px 12px 12px 0px;
          border-color: rgba(210, 219, 230, 1);
          border-style: solid;
          border-width: 1px;
          background-color: #fff;
          padding: 6px 12px;
        }
        .content {
          align-self: center;
          margin-top: 24px;
          width: 100%;
          max-width: 1396px;
          padding: 0 20px;
        }
        @media (max-width: 991px) {
          .content {
            max-width: 100%;
          }
        }
        .cards-container {
          gap: 20px;
          display: flex;
        }
        .cards-container {
          gap: 20px;
          display: flex;
          flex-direction:column //
        }
        @media (max-width: 991px) {
          .cards-container {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .card-column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 33%;
          margin-left: 0px;
        }
        .card-column {
          display: flex;
          flex-direction: column;
          line-height: normal;
          // width: 33%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .card-column {
            width: 100%;
          }
        }
        .card {
          border-radius: 12px;
          background-color: #fff;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          width: 100%;
          padding: 10px 10px 80px;
        }
        @media (max-width: 991px) {
          .card {
            max-width: 100%;
            margin-top: 8px;
          }
        }
        .card-header1 {
          // justify-content: space-between;
          // display: flex;
          // gap: 2px;
        }

        .card-header {
          justify-content: space-between;
          // display: flex;
          gap: 2px;
        }
        @media (max-width: 991px) {
          .card-header {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .card-title {
          color: var(--Black, #161616);
          letter-spacing: -0.42px;
          font: 500 21px/140% Inter, -apple-system, Roboto, Helvetica, sans-serif;
        }
        .card-icon {
          align-items: center;
          border-radius: 100px;
          border-color: rgba(211, 221, 232, 1);
          border-style: solid;
          border-width: 1px;
          background-color: #f3f8ff;
          display: flex;
          justify-content: center;
          width: 30px;
          height: 30px;
          padding: 10px;
        }
        .card-icon-image {
          aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
          width: 10px;
        }
        .card-divider {
          border-color: rgba(214, 220, 235, 1);
          border-style: solid;
          border-width: 1px;
          background-color: #d6dceb;
          margin-top: 11px;
          height: 1px;
        }
        @media (max-width: 991px) {
          .card-divider {
            max-width: 100%;
          }
        }
      `}</style>
      <main className="dashboard">

      <RequestPaymentonClick
          fractalpayClientKey={fractalpayClientKey}
          amount={amount}
          phone_number={phone_number}
        />
        <GetPaymentPage fractalpayClientKey={fractalpayClientKey} amount={7.19} />
        
                {/* <header className="header">
          <div className="header-content">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/c623d9aa98a89236f63d7d19e36bd73005d4e1fd8ca937326b6e90c2824b9ff9?apiKey=1b66e6c8161b4eb3b8bd375f2a0c3ba8&"
              alt="Geometric Brews logo"
              className="logo"
            />
            <nav className="nav-menu">
              <a href="#" className="nav-item-active">
                Home
              </a>
              <a href="#" className="nav-item">
                Payments
              </a>
              <a href="#" className="nav-item">
                Balances
              </a>
              <a href="#" className="nav-item">
                Customers
              </a>
              <a href="#" className="nav-item">
                Products
              </a>
              <a href="#" className="nav-item">
                Billing
              </a>
              <a href="#" className="nav-item">
                Reports
              </a>
              <a href="#" className="nav-item">
                Connect
              </a>
            </nav>
            <div className="user-info">
              <div className="user-name">Gaston Julia</div>
              <div className="user-avatar" />
            </div>
          </div>
        </header> */}


        <div class="b-loader" style={{ display: "none" }}>
          <div class="d-flex h-100">
            <div class="site-loader">
              <div class="wrap">
                <div class="loading">
                  <div class="bounceball"></div>
                  <div class="text">Please wait</div>
                  <p class="loader-p">while we process your request....</p>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="page-header">
          <h1 className="page-title">Geometric Brews</h1>
          <div className="page-actions">
            <button className="add-button">Add</button>
            <button className="done-button">Done</button>
          </div>
        </div>
        <div className="date-range">
          <div className="date-range-label">
            <div className="date-range-text">Last 7 days</div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d018832992272071761dbd91025016ed275f0ed75cd3525f2c17c94470b044b?apiKey=1b66e6c8161b4eb3b8bd375f2a0c3ba8&"
              alt=""
              className="date-range-icon"
            />
          </div>
          <div className="date-range-value">Mar 15-Mar 21</div>
        </div>



        <section className="content">
          <div className="cards-container1">
            <div className="card-column1">
              {paymentData.map((item, index) => (
                <article key={index} className="card">
                  <div className="card-header">
                    <h2 className="card-title">{item.title}</h2>

                  </div>
                  <div className="card-divider" />
                  {/* <table id="fractal_complete_transactions" class="table"></table> */}
                  <CompletedTransactions fractalpayClientKey={fractalpayClientKey} />

                </article>  
              ))}
            </div>
            <div className="card-column1">
              {invoicesData.map((item, index) => (
                <article key={index} className="card">
                  <div className="card-header1">
                    <h2 className="card-title">{item.title}</h2>
                  </div>
                  <div className="card-divider" />

                  <table className="invoices-table">
                    <RequestPaymentDatatable fractalpayClientKey={fractalpayClientKey} />

                  </table>


                </article>
              ))}
            </div>





            {/* <div className="card-column">
              {customersData.map((item, index) => (
                <article key={index} className="card">
                  <div className="card-header">
                    <h2 className="card-title">{item.title}</h2>
                    
    
             
                  </div>
                  <div className="card-divider" />
                  <div id="fractalpay_customer_details">
                <table class="table" id="fractal_public_customer_details"></table>
                <table class="table" id="fractal_public_customer_cards"></table>
                      </div>
                </article>
              ))}
            </div> */}

            <div className="card-column1">
              {customersData.map((item, index) => (
                <article key={index} className="card">
                  <div className="card-header1">
                    <h2 className="card-title">{item.title}</h2>


                    <CustomerDatable fractalpayClientKey={fractalpayClientKey} />


                  </div>
                  <div className="card-divider" />

                </article>
              ))}
            </div>

            {/* <div>
              <h2>All widgets</h2>
              <div className='container' >
            <RequestPayment fractalpayClientKey={fractalpayClientKey}/>
            <RequestPaymentDatatable fractalpayClientKey={fractalpayClientKey}/>
            <AddCustomer fractalpayClientKey={fractalpayClientKey}/>
            <CompletedTransactions fractalpayClientKey={fractalpayClientKey}/>
            <PayNow fractalpayClientKey={fractalpayClientKey}/>
    </div>
            </div> */}
          </div>
        </section>


        <section className="content">
          <div className="cards-container">
            <div className="card-column">
              {paymentData.map((item, index) => (
                <article key={index} className="card">
                  <div className="card-header">
                    <h2 className="card-title">{item.title}</h2>

                  </div>
                  <div className="card-divider" />
                  {/* <table id="fractal_complete_transactions" class="table"></table> */}
                  <CompletedTransactions fractalpayClientKey={fractalpayClientKey} />

                </article>
              ))}
            </div>
            <div className="card-column">
              {invoicesData.map((item, index) => (
                <article key={index} className="card">
                  <div className="card-header">
                    <h2 className="card-title">{item.title}</h2>
                  </div>
                  <div className="card-divider" />

                  <table className="invoices-table">
                    <RequestPaymentDatatable fractalpayClientKey={fractalpayClientKey} />

                  </table>


                </article>
              ))}
            </div>





            {/* <div className="card-column">
              {customersData.map((item, index) => (
                <article key={index} className="card">
                  <div className="card-header">
                    <h2 className="card-title">{item.title}</h2>
                    
    
             
                  </div>
                  <div className="card-divider" />
                  <div id="fractalpay_customer_details">
                <table class="table" id="fractal_public_customer_details"></table>
                <table class="table" id="fractal_public_customer_cards"></table>
                      </div>
                </article>
              ))}
            </div> */}

            <div className="card-column">
              {customersData.map((item, index) => (
                <article key={index} className="card">
                  <div className="card-header">
                    <h2 className="card-title">{item.title}</h2>


                    <CustomerDatable fractalpayClientKey={fractalpayClientKey} />


                  </div>
                  <div className="card-divider" />

                </article>
              ))}
            </div>

            {/* <div>
              <h2>All widgets</h2>
              <div className='container' >
            <RequestPayment fractalpayClientKey={fractalpayClientKey}/>
            <RequestPaymentDatatable fractalpayClientKey={fractalpayClientKey}/>
            <AddCustomer fractalpayClientKey={fractalpayClientKey}/>
            <CompletedTransactions fractalpayClientKey={fractalpayClientKey}/>
            <PayNow fractalpayClientKey={fractalpayClientKey}/>
    </div>
            </div> */}
          </div>
        </section>
      </main>
      <InvoiceTable />
    </>
  );
}
export default DashboardTest;