import React, { useState } from 'react';
import './IFrame.css';

// Assuming REACT_APP_API_URL is correctly set in your .env file, like "http://localhost:3001"
const cms_server_url = process.env.REACT_APP_CMS_SERVER_URL;

const Demoiframe = () => {
  const [amount, setAmount] = useState('');
  const [paymentUrl, setPaymentUrl] = useState('about:blank');
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formatCurrency = (value) => {
    let formattedValue = value.replace(/[^0-9.]/g, '');
    if (formattedValue.includes('.')) {
      const parts = formattedValue.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      parts[1] = parts[1].slice(0, 2);
      formattedValue = parts.join('.');
    } else {
      formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return formattedValue;
  };

  const handleInputChange = (e) => {
    const formattedValue = formatCurrency(e.target.value);
    setAmount(formattedValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const numericAmount = parseFloat(amount.replace(/,/g, '').replace('$', '')).toFixed(2);
    if (!numericAmount || isNaN(numericAmount)) {
      alert('Please enter a valid amount.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(`${cms_server_url}/api/getPaymentUrl`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ amount: numericAmount })
      });

      if (response.ok) {
        const data = await response.json();
        if (data.paymentUrl) {
          setPaymentUrl(data.paymentUrl);
          setShowModal(true);
        } else {
          // Adjust based on your actual API's error response structure
          alert('Failed to create the order. Please try again.');
        }
      } else {
        // This could be improved with custom error messages based on response status
        alert('Failed to create the order. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="invoice-item">
        <img src="https://cdn.dribbble.com/users/1158215/screenshots/15718091/media/d2caa924387bd19f3e5f55ee49c71f6c.jpg" alt="Product" />
        <div className="merchant-name">Acme, Inc.</div>
        <div className="pay-text">Pay Acme</div>
        <div className="price">{`$${amount || '0.00'}`}</div>
      </div>
      <div className='amount_sale'>
        <label htmlFor="amountInput" className="form-label">Amount of Sale</label>
        <input 
          type="text" 
          id="amountInput" 
          className="form-control mb-2" 
          placeholder="Enter amount" 
          value={amount} 
          onChange={handleInputChange} 
          disabled={isLoading}
        />
        <button className="sale-submit-btn" onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? 'Processing...' : 'Create Sale'}
        </button>
      </div>

      {/* {showModal && (
        <div className="modal show">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <button type="button" className="close-iframe-btn" onClick={() => setShowModal(false)}>×</button>
                <iframe src={paymentUrl} title="Payment" frameBorder="0" style={{width: '100%', height: '500px'}}></iframe>
              </div>
            </div>
          </div>
        </div>
      )} */}
      {showModal && (
  <div className="modal-overlay">
    <div className="modal-content">
      <button type="button" className="close-iframe-btn" onClick={() => setShowModal(false)}>×</button>
      <iframe src={paymentUrl} title="Payment" frameBorder="0"></iframe>
    </div>
  </div>
)}

    </div>
  );
};

export default Demoiframe;
