import React, { useState, useEffect, useRef } from 'react';
import './style.css';
import InvoiceTable from './InvoiceTableRequest';
import Navbar from './Navbar';
import Footer from './Footer';

const RequestWidget = () => {
    const [scrollTop, setScrollTop] = useState(0);
    const footerRef = useRef(null);

    const scrollToFooter = () => {
        footerRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        const handleScroll = () => {
            setScrollTop(window.pageYOffset || document.documentElement.scrollTop);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        const navbar = document.querySelector('.main-nav');

        if (navbar) {
            if (scrollTop > 63) {
                navbar.classList.add('navbar-fixed');
            } else {
                navbar.classList.remove('navbar-fixed');
            }
        }
    }, [scrollTop]);

    const getPaymentPage = (amount) => {
        console.log(`Redirecting to payment page for amount: ${amount}`);
        // Implement the function to handle payment redirection
    };

    return (
        <div>
            <Navbar />
            <div className="pay-widget-container">
            <div className="container">
                <h1>Link Widget</h1>
                <p>Integrating our link widget is straightforward, requiring only the addition of a single div class. Your application provides us with the payment amount and the customer's phone number. The customer will then receive a payment link to complete the transaction.</p>
                <div className="pay-widget-content">
                        <div className="row">
                    <section className="invoice-section col-md-8">
                        <InvoiceTable />
                    </section>
                    <section className="code-snippet-section col-md-4">
                        <div className="py-8">
                            <h2>Code Snippet</h2>
                            <pre>
                                <code class="line-break">
{`<a href="javascript:void(0);" 
onclick="sendRequestPayment(8,'9695969889')"
class="btn btn-primary">
Request payment</a>`}
                                </code>
                            </pre>
                        </div>
          
                        <div className="py-8">
                            <h2>Your Customer's View</h2>
                            <img src='fractalpaymentrequest.png'/>
                        </div>
                    </section>
                </div>
                </div>
            </div>
            </div>
            <Footer ref={footerRef} />
        </div>
    );
};

export default RequestWidget;
