import React, { useState, useEffect, useRef } from 'react';
// import Navbar from './Navbar';
// import Footer from './Footer';
import './InvoiceTable.css';
import { CompletedTransactions, PayNow, RequestPaymentDatatable, RequestPayment, AddCustomer, CustomerDatable, GetPaymentPage } from 'fractalpay';

const InvoiceTable = () => {
//   const [scrollTop, setScrollTop] = useState(0);
//   const footerRef = useRef(null);

//   const scrollToFooter = () => {
//     footerRef.current?.scrollIntoView({ behavior: 'smooth' });
//   };

  const fractalpayClientKey = "2c420c812f87c294d9890b6df134fcd6";

  // useEffect(() => {
  //   const handleScroll = () => {
  //     setScrollTop(window.pageYOffset || document.documentElement.scrollTop);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // useEffect(() => {
  //   const navbar = document.querySelector('.main-nav');

  //   if (navbar) {
  //     if (scrollTop > 63) {
  //       navbar.classList.add('navbar-fixed');
  //     } else {
  //       navbar.classList.remove('navbar-fixed');
  //     }
  //   }
  // }, [scrollTop]);

  return (
    <div>
      {/* <Navbar /> */}
      {/* <div className="container"> */}
        <div className="py-8">
          <div>
            <h2 className="text-2xl font-semibold leading-tight">Invoices</h2>
          </div>
          <div className="overflow-x-auto">
            <div className="table-wrapper">
              <table className="table">
                <thead>
                  <tr>
                    <th className="table-header">Client / Invoice</th>
                    <th className="table-header">Amount</th>
                    <th className="table-header">Issued / Due</th>
                    <th className="table-header">Status</th>
                    <th className="table-header"></th>
                  </tr>
                </thead>
                <tbody>
                                    <tr>
                    <td className="table-cell">
                      <div className="flex">
                        <div className="profile-img-wrapper">
                          <img className="profile-img" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80" alt="Profile" />
                        </div>
                        <div className="ml-3">
                          <p className="text-gray-900 whitespace-no-wrap">Molly Sanders</p>
                          <p className="text-gray-600 whitespace-no-wrap">000004</p>
                        </div>
                      </div>
                    </td>
                    <td className="table-cell">
                      <p className="text-gray-900 whitespace-no-wrap">$4.55</p>
                      <p className="text-gray-600 whitespace-no-wrap">USD</p>
                    </td>
                    <td className="table-cell">
                      <p className="text-gray-900 whitespace-no-wrap">Sept 28, 2024</p>
                      <p className="text-gray-600 whitespace-no-wrap">Due in 3 days</p>
                    </td>
                    <td className="table-cell">
                      <span className="status pending">Pending</span>
                    </td>
                    <td className="table-cell table-cell-center">
                      <GetPaymentPage fractalpayClientKey={fractalpayClientKey} amount={4.55} />
                    </td>
                  </tr>
                  <tr>
                    <td className="table-cell">
                      <div className="flex">
                        <div className="profile-img-wrapper">
                          <img
                            className="profile-img"
                            src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                            alt="Profile"
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-gray-900 whitespace-no-wrap">Michael Roberts</p>
                          <p className="text-gray-600 whitespace-no-wrap">000003</p>
                        </div>
                      </div>
                    </td>
                    <td className="table-cell">
                      <p className="text-gray-900 whitespace-no-wrap">$8.55</p>
                      <p className="text-gray-600 whitespace-no-wrap">USD</p>
                    </td>
                    <td className="table-cell">
                      <p className="text-gray-900 whitespace-no-wrap">Sept 25, 2024</p>
                      <p className="text-gray-600 whitespace-no-wrap">Due in 6 days</p>
                    </td>
                    <td className="table-cell">
                      <span className="status pending">Pending</span>
                    </td>
                    <td className="table-cell table-cell-center">
                    <GetPaymentPage fractalpayClientKey={fractalpayClientKey} amount={8.55} />
                    </td>
                  </tr>
                  <tr>
                    <td className="table-cell">
                      <div className="flex">
                        <div className="profile-img-wrapper">
                          <img
                            className="profile-img"
                            src="https://images.unsplash.com/photo-1540845511934-7721dd7adec3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                            alt="Profile"
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-gray-900 whitespace-no-wrap">Devin Childs</p>
                          <p className="text-gray-600 whitespace-no-wrap">000002</p>
                        </div>
                      </div>
                    </td>
                    <td className="table-cell">
                      <p className="text-gray-900 whitespace-no-wrap">$7.19</p>
                      <p className="text-gray-600 whitespace-no-wrap">USD</p>
                    </td>
                    <td className="table-cell">
                      <p className="text-gray-900 whitespace-no-wrap">Sept 14, 2024</p>
                      <p className="text-gray-600 whitespace-no-wrap">Due in 2 weeks</p>
                    </td>
                    <td className="table-cell">
                      <span className="status pending">Pending</span>
                    </td>
                    <td className="table-cell table-cell-center">
                    <GetPaymentPage fractalpayClientKey={fractalpayClientKey} amount={7.19} />

                    </td>
                  </tr>
                  <tr>
                    <td className="table-cell">
                      <div className="flex">
                        <div className="profile-img-wrapper">
                          <img
                            className="profile-img"
                            src="https://images.unsplash.com/photo-1522609925277-66fea332c575?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&h=160&w=160&q=80"
                            alt="Profile"
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-gray-900 whitespace-no-wrap">Frederick Nicholas</p>
                          <p className="text-gray-600 whitespace-no-wrap">000001</p>
                        </div>
                      </div>
                    </td>
                    <td className="table-cell">
                      <p className="text-gray-900 whitespace-no-wrap">$12.00</p>
                      <p className="text-gray-600 whitespace-no-wrap">USD</p>
                    </td>
                    <td className="table-cell">
                      <p className="text-gray-900 whitespace-no-wrap">Sept 6, 2024</p>
                      <p className="text-gray-600 whitespace-no-wrap">Due 3 weeks ago</p>
                    </td>
                    <td className="table-cell">
                      <span className="status overdue">Overdue</span>
                    </td>
                    <td className="table-cell table-cell-center">
                    <GetPaymentPage fractalpayClientKey={fractalpayClientKey} amount={12.00} />
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        {/* </div> */}
      </div>
      {/* <Footer ref={footerRef} /> */}
    </div>
  );
};

export default InvoiceTable;
