// import React, { useState, useRef } from "react";
// import GetStartedModal from './GetStartedModal';


// const Navbar = () =>{
//   const [isOpen, setIsOpen] = useState(false); // This might be for your menu toggle
//   const [showGetStartedModal, setShowGetStartedModal] = useState(false);

//   const footerRef = useRef(null);

//   const scrollToFooter = () => {
//     footerRef.current?.scrollIntoView({ behavior: 'smooth' });
//   };

//   const handleGetStartedClick = () => {
//       setShowGetStartedModal(true);
//     };
    
//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };
//     return(
//         <>
//            <nav className='main-nav'>
//         <div className="container">
//           <div className="row">
//             <div className="col-md-5 col-sm-6 col-sm-6">
//               <div className="logo">
//               <a href="/">
//                 <img src="logo.png" alt="Logo Image" />
//                 </a>
//               </div>
//             </div>
//             <div className="col-md-7 col-sm-6 col-sm-6">
//               <div className={`hamburger ${isOpen ? 'toggle' : ''}`} onClick={toggleMenu}>
//                 <div className="line"></div>
//                 <div className="line"></div>
//                 <div className="line"></div>
//               </div>
//               <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
//                 {/* <li className={`fade ${isOpen ? 'fade' : ''}`}><a href='javascript:void(0)'>Main</a></li>
//                 <li className={`fade ${isOpen ? 'fade' : ''}`}><a href='javascript:void(0)'>How</a></li>
//                 <li className={`fade ${isOpen ? 'fade' : ''}`}><a href='javascript:void(0)'>Cases</a></li> */}
//                 {/* <li className={`fade ${isOpen ? 'fade' : ''}`}><a href='javascript:void(0)' className='get-started' onClick={handleGetStartedClick}>Get started</a></li> */}
//                 <li className={`fade ${isOpen ? 'fade' : ''}`}><a href='javascript:void(0)' className='get-started' onClick={scrollToFooter}>Get started</a></li>

                

//               </ul>
//               {/* <GetStartedModal showModal={showGetStartedModal} setShowModal={setShowGetStartedModal} /> */}
//             </div>
//           </div>
//         </div>
//       </nav>
//         </>
//     )
// }
// export default Navbar;

import React, { useState } from "react";

const Navbar = ({ scrollToFooter }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <nav className='main-nav'>
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-sm-6">
              <div className="logo">
                <a href="/">
                  <img src="Logo_Fractal_Icon_Black.svg" alt="Logo Image" />
                </a>
              </div>
            </div>
            <div className="col-md-7 col-sm-6">
              <div className={`hamburger ${isOpen ? 'toggle' : ''}`} onClick={toggleMenu}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
              <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
                 {/* <li className={`fade ${isOpen ? 'fade' : ''}`}><a href='javascript:void(0)'>Main</a></li> */}
                 <li className={``}><a href='/About'>Team</a></li>
                 {/* <li className={`fade ${isOpen ? 'fade' : ''}`}><a href='javascript:void(0)'>How</a></li> */}
                 {/* <li className={`fade ${isOpen ? 'fade' : ''}`}><a href='javascript:void(0)'>Cases</a></li> */}
                 {/* <li className={`fade ${isOpen ? 'fade' : ''}`}><a href='javascript:void(0)' className='get-started' onClick={handleGetStartedClick}>Get started</a></li> */}
                <li className={``}><a href='javascript:void(0)' className='get-started' onClick={scrollToFooter}>Get started</a></li>
              </ul>
             {/* <GetStartedModal showModal={showGetStartedModal} setShowModal={setShowGetStartedModal} /> */}

            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
