// import logo from './logo.svg';
import React, { useState, useEffect } from "react";
import "../../src/App.css";
import "./style.css";
import "./responsive.css";
import "./quick-pay.css";
<link
  href="https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
  rel="stylesheet"
></link>;

const PaymentSuccessful = () => {
  const [scrollTop, setScrollTop] = useState(0);


  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.pageYOffset || document.documentElement.scrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const navbar = document.querySelector(".main-nav");

    if (navbar) {
      if (scrollTop > 63) {
        navbar.classList.add("navbar-fixed");
      } else {
        navbar.classList.remove("navbar-fixed");
      }
    }
  }, [scrollTop]);




  return (
    <div className="row bg-light no-gutters">
      <div className="col-sm-12">
        <div className="bd-example bd-example-tabs right-tabs mttabs m-3 ">
          <div className="payment-popup">
            <button className="close-pop">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="37"
                height="37"
                viewBox="0 0 21 21"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  // stroke-linecap="round"
                  // stroke-linejoin="round"
                  d="m7.5 7.5l6 6m0-6l-6 6"
                />
              </svg>
            </button>
            <div className="row">
              <div className="comp-name">
                <img src="comp-logo.png" alt="" className="client-logo" />
              </div>
              <div className="container">
                <div className="payment-success">

                  <div className="payment-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 14 14">
                      <path fill="none" stroke="#61C699" stroke-linecap="round" stroke-linejoin="round" d="m.5 8.55l2.73 3.51a1 1 0 0 0 .78.39a1 1 0 0 0 .78-.36L13.5 1.55" />
                    </svg>
                  </div>
                  <h2>Your payment<br />was successful</h2>
                  <p>Thank you for your payment.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessful;
